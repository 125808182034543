import { generateBooleanSchema, generateDateTimeSchema, generateDurationSchema, generateMediaUrlSchema, generateObjectSchema, generateRootObjectSchema, generateStringSchema, generateUrlSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { AuthorParticleSpec, ButtonParticleSpec, ImageParticleSpec, ShareStripParticleSpec, TextParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("Article Header-B", generateRootObjectSchema({
    container: generateAtomContainerSchema({
        defaultValues: { themeMode: 'primary' }
    }),
    slug: generateStringSchema("Slug", "The slug for this media item. This will be used to generate the media item link if an explicit link is not provided."),
    link: generateUrlSchema("Link", "The link for this media item. The default link will be generated from the slug if this is not provided (and the slug is)."),
    imageUrl: generateMediaUrlSchema("Image URL", "The image URL for social sharing."),
    publishDateTime: generateDateTimeSchema("Publish Date and Time", "The date and time that this content was published."),
    viewTimeDuration: generateDurationSchema("View Time Duration", "The duration that an average user would take to completely consume this content."),
    publishDateAndDurationText: TextParticleSpec.generateSchema("Publish Date and Read Time Text", "The styling for the publish date and read time of the article.", {
        defaultValues: { size: '1rem', weight: '700', align: "left", transform: 'capitalize' },
        uneditableFields: { text: true }
    }),
    author: generateObjectSchema("Author Info", "Author Info", {
        image: ImageParticleSpec.generateSchema("User Icon", "User Icon", {
            defaultValues: { container: { height: "6rem", width: "6rem" }, sizing: "cover" }
        }),
        nameText: TextParticleSpec.generateSchema("Author Name", "Author Name", {
            defaultValues: { size: '1.25rem', weight: '800', align: "left" },
        }),
        positionText: TextParticleSpec.generateSchema("Position", "Position", {
            defaultValues: { size: '1rem', weight: '300', style: "italic", align: "left" },
        }),
    }),
    showLocationText: generateBooleanSchema("Show Location Text", "Show Location Text", {
        defaultValue: true
    }),
    locationText: TextParticleSpec.generateSchema("Location", "Location", {
        defaultValues: { size: '1rem', weight: '300', align: "left" },
    }),
    txtShareText: TextParticleSpec.generateSchema("Share Text", "Share Text", {
        defaultValues: { size: "1.25rem", weight: "800", text: "Share this", wrap: "nowrap" }
    }),
    titleText: TextParticleSpec.generateSchema("Share Text", "Share Text", {
        defaultValues: { size: "1.25rem", weight: "800", text: "Share this" }
    }),
    imgShareIcon: ButtonParticleSpec.generateSchema("Share Icon", "Share Icon", {
        defaultValues: { image: { container: { width: "2rem", height: "2rem" } }, showText: false }
    }),
    imgTwitter: ImageParticleSpec.generateSchema("Twitter Icon", "Twitter Icon", {
        defaultValues: { container: { width: "2rem", height: "2rem" } }
    }),
    imgFacebook: ImageParticleSpec.generateSchema("Facebook Icon", "Facebook Icon", {
        defaultValues: { container: { width: "2.5rem", height: "2.5rem" } }
    }),
    imgLine: ImageParticleSpec.generateSchema("Line Icon", "Line Icon", {
        defaultValues: { container: { width: "2.5rem", height: "2.5rem" } }
    }),
    imgWeibo: ImageParticleSpec.generateSchema("Weibo Icon", "Weibo Icon", {
        defaultValues: { container: { width: "2.5rem", height: "2.5rem" } }
    }),
    showUserIconIn: generateBooleanSchema("Show User Icon", "Show User Icon", {
        defaultValue: true
    }),
    showAuthorIn: generateBooleanSchema("Show Author", "Show Author block.", {
        defaultValue: true
    }),
    showPublishDateAndDuration: generateBooleanSchema("Show Publish Date and Read Time", "Show publish date and read time line.", {
        defaultValue: true
    }),
    showSecondAuthorInfo: generateBooleanSchema("Show Second Author Info", "Show Second Author Info", {
        defaultValue: true
    }),
    showShareStrip: generateBooleanSchema("Show Social Media Icons", "Show Social Media Icons", {
        defaultValue: true
    }),
    showTwitter: generateBooleanSchema("Show Twitter", "Show Twitter", {
        defaultValue: true
    }),
    showFacebook: generateBooleanSchema("Show Facebook", "Show Facebook", {
        defaultValue: true
    }),
    showLine: generateBooleanSchema("Show Line", "Show Line", {
        defaultValue: true
    }),
    showWeibo: generateBooleanSchema("Show Weibo", "Show Weibo", {
        defaultValue: true
    }),
    showShareLink: generateBooleanSchema("Show Copy Link", "Show Copy Link", {
        defaultValue: true
    }),
    showShareMail: generateBooleanSchema("Show Copy Link", "Show Copy Link", {
        defaultValue: true
    }),
    setPageMetadata: generateBooleanSchema("Set Page Metadata", "Allow this card to set the metadata on the page, such as the page title.", {
        defaultValue: false
    }),
    infoItemsGap: generateStringSchema("Info Items Gap", "Info Items Gap"),
}));
