<CardBase {element}>
  {#if (appOnly && Device.cordova) || (!appOnly && !webOnly) || (webOnly && !Device.cordova)}
    <YCLink resolved={url} style="width:100%;">
      <div class="simple-card {layout || ''}" on:click={handleClick}>
        {#if thumbnail}
        <div class="thumbnail">
          <img src={thumbnail} alt={title || 'thumbnail'} />
        </div>
        {/if}
        {#if title}
        <div class="title" style="color:{fontColor}; font-size:{fontSize}; {textStyle}">{title}</div>
        {/if}
      </div>
    </YCLink>
  {/if}
</CardBase>

<style>
  .simple-card {
    width:100%;
    color:#FFF;
    -webkit-text-decoration:none;
    text-decoration:none;
    display:flex;
    flex-wrap:wrap;
    height: 40px;
    align-items: center;
    justify-content: center;
  }

  .simple-card > div {
    width:100%;
  }

  .simple-card .thumbnail img {
    width:100%;
    height:intrinsic;
    height:auto;
  }

  .simple-card .title {
    padding:6px;
    text-align:center;
  }

  .simple-card.inline-logo-left, .simple-card.inline-logo-right {
    width:100%;
    display:flex;
    justify-content:space-around;
    align-items:center;
  }

  .simple-card.inline-logo-left .thumbnail, .simple-card.inline-logo-right .thumbnail {
    width:56px;
  }

  .simple-card.inline-logo-left .title, .simple-card.inline-logo-right .title {
    width:auto;
    font-weight:bold;
    text-transform:uppercase;
  }

  .simple-card.inline-logo-right .thumbnail {
    order:2;
  }

  .simple-card.inline-logo-right .title {
    order:1;
  }</style>

<script lang="ts">var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
import "yinzcam-cards";
import CardBase from "../../common/CardBase.svelte";
import { t } from "../../../../js/i18n";
import { resolveUrl } from "../../../../js/url";
import YCLink from "../../utilities/YCLink.svelte";
import { Device } from 'framework7';
import { getFirstSourceStore } from "../../utilities";
import "svelte/store";
import "../../common/CardsDataSourceRegistration";
import { CardsTabContextKey } from "../../common/context";
import { getContext } from "svelte";
export let sources;
export let element;
export let componentVisible = true;
const tabContext = getContext(CardsTabContextKey);
$: mergedParams = (_a = tabContext === null || tabContext === void 0 ? void 0 : tabContext.mergedParamsComp) === null || _a === void 0 ? void 0 : _a.store;
let hasData = false;
let registeredToSource = element === null || element === void 0 ? void 0 : element.sourceIds;
let source;
$: {
    source = getFirstSourceStore($sources);
}
$: if (registeredToSource) {
    componentVisible = !!$source;
}
let fontSize = '14px';
let fontColor = '#FFF';
let textStyle = '';
$: title = $t((_b = element === null || element === void 0 ? void 0 : element.data) === null || _b === void 0 ? void 0 : _b.title);
$: layout = $t((_c = element === null || element === void 0 ? void 0 : element.data) === null || _c === void 0 ? void 0 : _c.layout);
$: thumbnail = $t((_d = element === null || element === void 0 ? void 0 : element.data) === null || _d === void 0 ? void 0 : _d.thumbnail);
$: fontSize = (_e = element === null || element === void 0 ? void 0 : element.data) === null || _e === void 0 ? void 0 : _e.fontSize;
$: fontColor = (_f = element === null || element === void 0 ? void 0 : element.data) === null || _f === void 0 ? void 0 : _f.fontColor;
$: appOnly = (_g = element === null || element === void 0 ? void 0 : element.data) === null || _g === void 0 ? void 0 : _g.appOnly;
$: webOnly = (_h = element === null || element === void 0 ? void 0 : element.data) === null || _h === void 0 ? void 0 : _h.webOnly;
$: external = (_j = element === null || element === void 0 ? void 0 : element.data) === null || _j === void 0 ? void 0 : _j.external;
$: url = resolveUrl($t((_k = element === null || element === void 0 ? void 0 : element.data) === null || _k === void 0 ? void 0 : _k.url));
$: textStyle = (_l = element === null || element === void 0 ? void 0 : element.data) === null || _l === void 0 ? void 0 : _l.textStyle;
$: gameId = ($mergedParams === null || $mergedParams === void 0 ? void 0 : $mergedParams.gameId) || "";
$: detailsType = (_o = (_m = element === null || element === void 0 ? void 0 : element.data) === null || _m === void 0 ? void 0 : _m.gtmData) === null || _o === void 0 ? void 0 : _o.detailsType;
const handleClick = () => {
    if (detailsType) {
        const prefix = "YC_Analytics_";
        const competitionName = window.localStorage.getItem(prefix + "Competition_Name");
        const homeName = window.localStorage.getItem(prefix + "Home_Name");
        const awayName = window.localStorage.getItem(prefix + "Away_Name");
        const timeStamp = window.localStorage.getItem(prefix + "Timestamp");
        window['dataLayer'].push({
            event: 'match_details_viewed',
            login_status: 'guest', // One of: logged_in, guest
            crn: null, // CRN number of the user. Null if unavailable
            customer_id: null, // Unique customer ID, provided by SSO. Null if unavailable
            topics_followed: null, //List of topics (tags) followed by the user. Null if unavailable
            scope: CONFIG.primarySiteName,
            source: CONFIG.primaryDomainName,
            page_language: 'en',
            page_url: location.href,
            page_title: document.title,
            page_category: 'Match Centre',
            match_id: gameId,
            match_name: homeName + ' - ' + awayName, // Name of the match. Consists of {match_host_team} - {match_opponent_team}
            match_kickoff_datetime: timeStamp, // Kickoff datetime of the match. Format: YYYY-MM-DD HH:MM
            team_squad: 'men', // [ men, women, U23, U18 ]
            competition_name: competitionName, // Name of the competition. Example(s): Premier League, Carabao Cup
            match_host_team: homeName, // Name of the team that has hosted the match. Example(s): Tottenham Hotspur
            match_opponent_team: awayName, // Name of the opponent team. Example(s): Arsenal
            details_type: detailsType // [ 'commentary', 'lineups', 'stats', 'live_results' ]
        });
    }
};
</script>
