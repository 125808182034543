<script context="module" lang="ts">var _a;
export { specification } from "./spec";
</script>

<script lang="ts">var _a;
import "swiper/swiper.scss";
import BaseAtom from "components/cards/atoms/BaseAtom.svelte";
import { getContentManager, getModeManager, } from "components/cards/atoms";
import { ShareStripParticle, GalleryCarouselParticle, GalleryCarouselParticleSpec, } from "components/cards/particles/YinzCam";
import ImageParticle from "components/cards/particles/YinzCam/Core/Image/ImageParticle.svelte";
import TextParticle from "components/cards/particles/YinzCam/Core/Text/TextParticle.svelte";
export let container = undefined;
export let slug = undefined;
export let link = undefined;
export let carouselPositionText = undefined;
export let photoTitleText = undefined;
export let photoDescriptionText = undefined;
export let socialLinks = undefined;
export let currentPhoto = undefined;
export let carouselPhoto = undefined;
export let photos = undefined;
export let mobileViewPhotoGap;
export let albumTitle;
export let galleryDisplayOption;
$: mtc = getContentManager().getMediaTypeConfigurationStore("gallery", slug, link);
$: shareLink = (_a = $mtc === null || $mtc === void 0 ? void 0 : $mtc.shareLinkUrl) === null || _a === void 0 ? void 0 : _a.href;
$: isMobile = atomWidthRem < 50;
let curPhotoUrl = "";
let curPhotoTitle = "";
let curPhotoDesc = "";
let curPhotoStatusText = "";
let bottomSwiper;
let atomWidthRem;
$: if ((bottomSwiper === null || bottomSwiper === void 0 ? void 0 : bottomSwiper.swiper()) && (photos === null || photos === void 0 ? void 0 : photos.length) > 0) {
    const url = new URL(location.href);
    const currentPhotoUrl = url.searchParams.get("currentPhotoUrl");
    const currentPhotoIndex = Math.max((photos === null || photos === void 0 ? void 0 : photos.findIndex((e) => e.source === currentPhotoUrl)) || 0, 0);
    bottomSwiper === null || bottomSwiper === void 0 ? void 0 : bottomSwiper.swiper().slideTo(currentPhotoIndex);
    updatePhotoInfo(currentPhotoIndex);
}
function onSlideChange(e) {
    const index = e.detail[0][0].activeIndex;
    updatePhotoInfo(index);
    console.log("slide change: ", index);
}
function updatePhotoInfo(index) {
    const photo = photos === null || photos === void 0 ? void 0 : photos[index];
    curPhotoUrl = (photo === null || photo === void 0 ? void 0 : photo.source) || "";
    curPhotoTitle = (photo === null || photo === void 0 ? void 0 : photo.title) || "";
    curPhotoDesc = (photo === null || photo === void 0 ? void 0 : photo.description) || "";
    curPhotoStatusText = `Photo ${index + 1}/${photos === null || photos === void 0 ? void 0 : photos.length}`;
}
function onLeftClick() {
    let sw = bottomSwiper === null || bottomSwiper === void 0 ? void 0 : bottomSwiper.swiper();
    if (sw) {
        if (sw.isBeginning) {
            sw.slideTo(photos.length - 1);
        }
        else {
            sw.slidePrev();
        }
    }
}
function onRightClick() {
    let sw = bottomSwiper === null || bottomSwiper === void 0 ? void 0 : bottomSwiper.swiper();
    if (sw) {
        if (sw.isEnd) {
            sw.slideTo(0);
        }
        else {
            sw.slideNext();
        }
    }
}
function handleImageClick(index = "") {
    const imageDialog = document.getElementById(`imageDialog${index}`);
    imageDialog.showModal();
}
function handleDialogClose(index = "") {
    const imageDialog = document.getElementById(`imageDialog${index}`);
    imageDialog.close();
}
const mode = getModeManager();
$: langStore = mode.getCurrentLanguageStore();
$: langValue = ($langStore || "").toLowerCase();
</script>

<BaseAtom
  _isotope="PhotoViewerAtom"
  _cssVariablePrefix="gallery-viewer"
  {...container}
  {mobileViewPhotoGap}
  bind:_clientWidthRem={atomWidthRem}
>
  <div class="photo-viewer">
    {#if galleryDisplayOption === "infinite"}
      <div class="photo-share-links">
        <ShareStripParticle
          {...socialLinks}
          shareLink={shareLink || ""}
          shareText={albumTitle || ""}
          showShareWeibo={langValue !== "th"}
          showShareLine={langValue === "th"}
        />
      </div>
      <div class="mobile-photo-viewer">
        {#each photos || [] as photo, index}
          <div class="single-photo">
            <div class="photo" on:click={() => handleImageClick(`${index}`)}>
              <ImageParticle {...currentPhoto} source={photo?.source} />
            </div>
            <dialog id={`imageDialog${index}`} class="image-popup-dialog">
              <ImageParticle
                _block={true}
                {...currentPhoto}
                source={photo?.source}
                sizing="cover"
              />
              <button
                class="dialog-close-button"
                on:click={() => handleDialogClose(`${index}`)}>x</button
              >
            </dialog>
            <div class="text-content">
              <!-- <div class="photo-status">
              <TextParticle
                {...carouselPositionText}
                text={`Photo ${index + 1}/${photos?.length}`}
              />
            </div> -->
              <div class="photo-title-description">
                <div class="photo-title">
                  <TextParticle {...photoTitleText} text={photo?.title || ""} />
                </div>
              </div>
              <div class="photo-description">
                <TextParticle
                  {...photoDescriptionText}
                  text={photo?.description || ""}
                />
              </div>
            </div>
          </div>
        {/each}
      </div>
    {:else}
      <GalleryCarouselParticle {...GalleryCarouselParticleSpec.propsWithDefaults($$props)} />
    {/if}
  </div>
</BaseAtom>

<style>
  .photo-viewer {
    width: 100%;
  }

  .photo-viewer > .selected-image-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3rem;
  }

  .photo-viewer > .selected-image-row > .left-arrow, .photo-viewer > .selected-image-row > .right-arrow {
    flex: 0;
  }

  .photo-viewer > .selected-image-row > .selected-image {
    flex: 1;
    height: 100%;
  }

  .photo-viewer > .photo-info-row {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    padding: 1.75rem 5.5rem;
  }

  .photo-viewer > .photo-info-row > .photo-status {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
  }

  .photo-viewer > .photo-info-row > .photo-title-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
  }

  .photo-viewer > .photo-info-row > .photo-share-links {
    margin-left: auto;
  }

  .photo-viewer > .photo-share-links {
    display: flex;
    justify-content: flex-start;
    padding: 1rem 0;
  }

  .photo-viewer > .bottom-swiper-wrapper {
    padding: 0 5.5rem;
  }

  .photo-viewer > .bottom-swiper-wrapper :global(.swiper-slide) {
    flex-shrink: 1;
  }

  .mobile-photo-viewer {
    display: flex;
    flex-direction: column;
    gap: var(--gallery-viewer-mobile-view-photo-gap, 2rem);
  }

  .mobile-photo-viewer .text-content {
      display: flex;
      flex-direction: column;
    }

  .mobile-photo-viewer .text-content .photo-title-description {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
      }

  dialog {
    padding: 0;
    height: 100vh;
    width: 100vw;
  }
  .image-popup-dialog {
    border: none;
    border-radius: 2rem;
  }
  .dialog-close-button {
    position: absolute;
    right: 1rem;
    top: 0.5rem;
    background-color: transparent;
    font:
      2rem "Fira Sans",
      sans-serif;
    font-weight: 800;
    color: #c0b8b8;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    border: none;
  }</style>
