<YCPage query={pageQuery} bind:clearPreviousPage={clearPreviousPage}>
  {#if !isLoginRedirect && $initialPageContentLoaded && (!hideFooterInAppMode || pageQuery[appQSParam] !== "true") && !hideFooter}
  <CardsPage {listName} {pathParams} {pageQuery} {pageData} on:contentLoaded={() => clearPreviousPage()} />
  {/if}
</YCPage>

<style></style>

<script lang='ts'>import YCPage from '../components/YCPage.svelte';
import CardsPage from '../components/cards/common/CardsPage.svelte';
import { getFooterPageData } from '../footers';
import { writable } from "svelte/store";
import '../js/utilities';
import { initialPageContentLoaded } from 'js/routes';
import { hideHeaderAndFooter } from '../js/routes';
const isLoginRedirect = CONFIG.ssoOAuthRedirectPath === window.location.pathname;
const hideFooterInAppMode = CONFIG.hideFooterInAppMode;
const appQSParam = CONFIG.appModeQueryStringParameter;
export let f7route;
let clearPreviousPage;
$: hideFooter = $hideHeaderAndFooter;
const listName = f7route.params.listName;
const pathParams = (f7route.params.path1) ? [f7route.params.path1] : undefined;
const pageQuery = f7route.query;
const pageData = writable(getFooterPageData());
</script>
