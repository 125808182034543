import { generateBooleanSchema, generateDateTimeSchema, generateDurationSchema, generateRootObjectSchema, generateStringSchema, generateUrlSchema, generateArraySchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { AuthorParticleSpec, ShareStripParticleSpec, TextParticleSpec, ButtonParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("Article Header Card", generateRootObjectSchema({
  container: generateAtomContainerSchema({
    defaultValues: { themeMode: 'primary' }
  }),
  slug: generateStringSchema("Slug", "The slug for this media item. This will be used to generate the media item link if an explicit link is not provided."),
  link: generateUrlSchema("Link", "The link for this media item. The default link will be generated from the slug if this is not provided (and the slug is)."),
  publishDateTime: generateDateTimeSchema("Publish Date and Time", "The date and time that this content was published."),
  viewTimeDuration: generateDurationSchema("View Time Duration", "The duration that an average user would take to completely consume this content."),
  publishDateAndDurationText: TextParticleSpec.generateSchema("Publish Date and Read Time Text", "The styling for the publish date and read time of the article.", {
    defaultValues: { size: '1.125rem', weight: '400' },
    uneditableFields: { text: true }
  }),
  titleText: TextParticleSpec.generateSchema("Title Text", "The title text of the article.", {
    defaultValues: { size: '3rem', weight: '700' },
  }),
  descriptionText: TextParticleSpec.generateSchema("Description Text", "The description text of the article.", {
    defaultValues: { size: '1rem', weight: '600' },
  }),
  author: AuthorParticleSpec.generateSchema("Author", "The author of the article.", {
    defaultValues: { },
  }),
  socialLinks: ShareStripParticleSpec.generateSchema("Social Links", "The social links for the article.", {
    defaultValues: { },
    uneditableFields: { link: true }
  }),
  showAuthor: generateBooleanSchema("Show Author", "Show Author block.", {
    defaultValue: true
  }),
  showPublishDateAndDuration: generateBooleanSchema("Show Publish Date and Read Time", "Show publish date and read time line.", {
    defaultValue: true
  }),
  setPageMetadata: generateBooleanSchema("Set Page Metadata", "Allow this card to set the metadata on the page, such as the page title.", {
    defaultValue: false
  }),
  categories: generateArraySchema("Categories", "The categories to list for the media item.",
    ButtonParticleSpec.generateSchema("Category", "A category for the media item.", {
      defaultValues: {
        showImage: false, container: { themeMode: 'inverted' }, text: { size: "1.25rem", weight: "700" }
      }
    })
  ),
  shouldDisplayCategories: generateBooleanSchema("Should display categories?", "Specify if categories should be displayed"),
}));
